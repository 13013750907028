<template>
  <component :is="resolveLayout">
    <router-view></router-view>
    <v-snackbar
      v-model="show"
      :timeout="timeout"
      :color="color"      
      top
      right
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import store from './store/index'

export default {
    components: {
        LayoutBlank,
        LayoutContent,
        store  
    },
    setup() {
        const { route } = useRouter()
        console.log("creeating App setups")

        const resolveLayout = computed(() => {
            // Handles initial route
            if (route.value.name === null) return null

            if (route.value.meta.layout === 'blank') return 'layout-blank'

            return 'layout-content'
        })   

        return {
            resolveLayout
        }
    },
    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === "SHOW_MESSAGE") {
                this.text = state.text;
                this.color = state.color;
                this.timeout = state.timeout;
                this.show = state.show;
            } else if (mutation.type === "HIDE_MESSAGE") {
                this.show = state.show;
            }
        });
    },
    data() {
        return {
            show: false,
            color: "",
            text: "",
            timeout: -1,
        };
    },
}
</script>
