import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'
import mapboxgl from 'mapbox-gl'

Vue.config.productionTip = false
axios.defaults.withCredentials = false
Vue.prototype.$http = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}`
  });
console.log("env_variable:", process.env.VUE_APP_API_URL)
mapboxgl.accessToken = 'pk.eyJ1Ijoic2ViYXN0amFuZmFiaWphbnNvbHZlc2FsbCIsImEiOiJjbDV0YjNkOW8wY2h5M2N0OGl4djZleWF6In0.MgI10sDWJmBnCfYYf-io1Q'

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
