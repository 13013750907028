require('./overrides.scss')

export default {
    // TODO set a preferred color pallette
  theme: {
    themes: {
      light: {
        primary: '#285076',
        // accent: '#0d6efd',
        secondary: '#636363',
        // success: '#56CA00',
        info: '#51aaf0',
        warning: '#f59925',
        // error: '#FF4C51',
      }
    },
  },
}
