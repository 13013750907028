import Vue from 'vue'
import Vuex, { Store } from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        text: "",
        color: "",
        timeout: "",
        show: false
    },
    mutations: {
        SHOW_MESSAGE(state, payload) {
            state.text = payload.text;
            state.color = payload.color;
            state.timeout = payload.timeout;
            state.show = true
        },
        HIDE_MESSAGE(state) {
            state.show = false
        }
    },
})
